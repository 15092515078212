import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseModalModule } from '../base-modal/base-modal.module';
import { InfoDialogComponent } from './info-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        BaseModalModule
    ],
    declarations: [InfoDialogComponent],
    exports: [InfoDialogComponent]
})
export class InfoDialogModule {
}
